@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body,
div,
p,
a,
input,
textarea,
h3,
h4,
h5,
h6 {
    font-family: 'Roboto', sans-serif;
}

h1,
h2 {
    font-family: 'Playfair Display', serif;
}

.dropdown-toggle::after {
    display: none;
}

.form-control {
    padding: 10px 15px;
}

nav.navbar .navbar-brand img {
    width: 70px;
}

.megamenu {
    position: inherit;
}

.megamenu .dropdown-menu {
    width: 100%;
    box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.12);
    border: none;
    border-radius: 20px;
    padding: 15px;
}

.megamenu .dropdown-menu li {
    float: left;
    width: 25%;

}

.megamenu .dropdown-menu li a {
    text-decoration: none;
    font-weight: 500;
    font-size: 15px;
    color: #141414;
    padding: 16px 15px;
    border-radius: 8px;
    border-bottom: 4px solid transparent;
    display: block;
    position: relative;
}

.megamenu .dropdown-menu li a:hover {
    color: #DD3944;
    background: #fdf4f478;
    border-color: #DD3944;
}

.megamenu .dropdown-menu li a:hover:before {
    content: '\F138';
    font-family: 'bootstrap-icons';
    position: absolute;
    right: 12px;
    font-weight: 600;
    color: #141414;
}

.megamenu .dropdown-menu li a i {
    margin-right: 5px;
}

.try_free_btn {
    display: inline-block;
    background: #000;
    color: #fff;
    text-decoration: none;
    padding: 8px 24px;
    font-weight: 600;
    border-radius: 25px;
    transition: 0.3s;
}

.try_free_btn:hover {
    background: #dd3944;
    color: #fff;
}

.navbar-nav .nav-link {
    color: #000;
}

.main_banner h1 {
    margin-top: 60px;
    font-size: 45px;
}

.main_banner h1 span {
    font-weight: 600;
    color: #dd3944;
}

h5.yaams {
    padding: 10px;
    border-left: 3px solid #dd3944;
    margin-top: 20px;
    font-weight: 400;
}

.main_banner {
    background-position: bottom;
    background-image: url(./assets/images/banner-back.svg);
    background-size: cover;
}

.banner-form {
    display: inline-block;
    width: 80%;
}

.banner-form .form-group {
    float: left;
    width: calc(100% - 110px);
}

.banner-form label {
    color: #dd3944;
    font-weight: 500;
    margin-bottom: 4px;
}

.banner-form button {
    float: left;
    margin-top: 27px;
    width: 95px;
}

.manage_org {
    background: linear-gradient(45deg, #dd3944, #FF6771);
    color: #fff;
}

.manage_org img {
    width: 140px;
    margin-top: 18px;
    cursor: pointer;
}

.banner-form .form-group:before {
    content: '';
    width: 57px;
    height: 48px;
    background-image: url(./assets/images/three-lines.svg);
    position: absolute;
    right: -35px;
    top: -36px;
    background-repeat: no-repeat;
}

.manage_org h2 span:before {
    content: "";
    position: absolute;
    width: 243px;
    height: 40px;
    right: -100px;
    top: 32px;
    background: url(./assets/images/white-line.svg);
    background-repeat: no-repeat;
}

.big_heading {
    font-size: 35px;
    font-weight: 700;
}

.tabs_sec .big_heading span:before {
    content: "";
    position: absolute;
    left: -60px;
    top: -6px;
    width: 50px;
    height: 50px;
    background-image: url(./assets/images/star.svg);
    background-size: contain;
    background-repeat: no-repeat;
}

.tabs_sec .nav-pills .nav-link {
    background: #ececec;
    color: #000;
    border-radius: 5px;
}

.tabs_sec .nav-pills .nav-item {
    margin-right: 15px;
    margin-bottom: 10px;
}

.tabs_sec .nav-pills .nav-link.active {
    background: #dd3944;
    color: #fff;
}

.tabs_sec .tab-content {
    background: #FFF5F6;
    padding: 30px;
}

.feature_text ul {
    list-style: none;
    padding: 0;
}

.feature_text ul li {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 8px;
}

.feature_text ul li img {
    margin-right: 12px;
}

.feature_text .more {
    text-decoration: none;
    font-size: 18px;
    color: #000;
    font-weight: 500;
}

.feature_text .more i {
    color: #dd3944;
}

.what_new {
    background-position: center;
}

.what_new_box {
    height: 100%;
}

.what_new_box img {
    width: 60px;
    filter: grayscale(1);
}

.what_new_box:hover img {
    filter: inherit;
}

.what_new .col-md-4 {
    margin-bottom: 45px;
}

.what_new h2 span:before {
    content: "";
    position: absolute;
    width: 32px;
    height: 30px;
    background: url(./assets/images/whats-new-brd.svg);
    background-size: contain;
    background-repeat: no-repeat;
    right: -33px;
    top: -12px;
}

.what_new h4 {
    font-weight: 600;
}

.app_interface .slick-slide img {
    display: block;
    margin: auto;
    width: 70%;
    margin-top: 45px;
    transition: 0.3s;
    filter: drop-shadow(2px 14px 8px #c7c7c7);
}

.app_interface .slick-slide.slick-center img {
    width: 84%;
    margin-top: 0;
}

.app_interface ul.slick-dots {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    justify-content: center;
}

.app_interface ul.slick-dots li {
    margin: 0 5px;
}

.app_interface ul.slick-dots li button {
    background: #D8D8D8;
    font-size: 0;
    border: none;
    height: 12px;
    width: 12px;
    border-radius: 50%;
}

.app_interface ul.slick-dots li.slick-active button {
    background: #DD3944;
}

.app_interface .slick-arrow {
    background: #FFEEEF;
    border: none;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    position: absolute;
    top: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.app_interface .slick-arrow svg {
    font-size: 24px;
    color: #000;
}

.app_interface .slick-arrow:hover svg {
    color: #fff;
}

.app_interface .slick-arrow:hover {
    background: #DD3944;
    color: #fff;
}

.app_interface .slick-next.slick-arrow {
    right: 0;
}

.app_interface .slick-prev.slick-arrow {
    left: 0;
}

.app_interface .slick-list {
    width: 90%;
    margin: 40px auto;
}

.app_interface .slick-arrow:before {
    display: none;
}

.free_trial {
    text-decoration: none;
    background: #fff;
    display: inline-block;
    padding: 12px 20px;
    border-radius: 10px;
    color: #dd3944;
    margin-top: 20px;
    font-weight: 600;
}

.get_started_box {
    text-align: center;
    background: #FFF5F6;
    padding: 35px 25px;
    margin-bottom: 20px;
    border-radius: 20px;
}

.get_started_box span {
    background: #dd3944;
    height: 40px;
    width: 40px;
    display: flex;
    color: #fff;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    margin: 0 auto 20px;
}

.arrow-top-down {
    position: absolute;
    right: -80px;
    top: -32px;
}

.arrow-bottom-up {
    position: absolute;
    right: -85px;
    bottom: -20px;
}

.lets_connect {
    background-image: url(./assets/images/form-bg.svg);
    background-size: cover;
    background-position: center;
}

.form_box {
    background: rgba(255, 255, 255, 0.2);
    padding: 30px;
    border-radius: 20px;
    backdrop-filter: blur(25.4486px);
}

.sbmit_btn button {
    background: #dd3944;
    border: none;
    color: #fff;
    border-radius: 8px;
    padding: 10px 34px;
    font-size: 18px;
    margin-top: 15px;
    transition: 0.3s;
}

.sbmit_btn button:hover {
    background: #141414;

}

.faq_sec .accordion-button {
    color: #52545D !important;
    font-size: 18px;
    background: transparent !important;
}

.faq_sec .accordion-button:focus {
    box-shadow: none;
}

.faq_sec .accordion-button:not(.collapsed) {
    font-weight: 600;
    color: #52545D;
}

.faq_sec .accordion-button:not(.collapsed):after {
    transform: none;
}

.faq_sec .accordion-item {
    margin-bottom: 12px;
    background: #F9F7F8;
    border-radius: 10px;
    border: none;
}

.faq_sec .accordion-button::after {
    content: '';
    background-image: none;
    height: 0px;
    width: 17px;
    background:#474747;
    border: 1px solid #dd3944;
}
.faq_sec .accordion-button::before{
    content: '';
    position: absolute;
    width: 0px;
    height: 17px;
    background: #474747;
    right: 27px;
    top: 18px;
    border: 1px solid #dd3944;
    transition: 0.3s;
}
.faq_sec .accordion-button:not(.collapsed):before {
    transform: rotate(90deg);
}

.faq_sec .question_mark {
    position: absolute;
    right: 0;
    top: -12px;
    width: 150px;
    transform: rotate(15deg);
}

.orange_white {
    background: #dd3944;
    text-decoration: none;
    color: #fff;
    display: inline-block;
    padding: 10px 30px;
    border-radius: 25px;
    transition: 0.3s;
    margin-top: 10px;
}

.orange_white:hover {
    background: #fff;
    color: #dd3944;
}

.footer_logo {
    width: 120px;
}

.footer_menu {
    list-style: none;
    padding: 0;
    margin-top: 15px;
}

.footer_menu li {
    margin-bottom: 6px;
}

.footer_menu li a {
    color: #fff;
    text-decoration: none;
}

.contact_details a {
    text-decoration: none;
    color: #fff;
    display: flex;
    margin-top: 12px;
    align-items: center;
}

.contact_details a svg {
    margin-right: 8px;
    margin-top: 5px;
}

.footer_social {
    list-style: none;
    padding: 0;
    margin-top: 25px;
    display: flex;
}

.footer_social li {
    margin-right: 12px;
}

.footer_social li a {
    color: #fff;
}


.bottom_bar {
    border-top: 1px solid #fff;
    padding: 25px 0;
    display: flex;
    justify-content: space-between;
}

.bottom_bar a {
    color: #fff;
    margin-right: 15px;
}

/***Inner pages***/
.detail_box {
    font-size: 15px;
}

.detail_box a {
    text-decoration: none;
    color: #141414;
}

.inner_background {
    background-color: rgba(255, 238, 239, 0.53);
}

.detail_box i {
    font-size: 26px;
    color: #dd3944;
    display: inline-block;
    margin-bottom: 5px;
}

.what_we_box:hover img {
    transform: translateY(10px);
}

.what_we_box img {
    width: 85px;
    margin-bottom: 15px;
    transition: 0.5s;
}

.vision_box {
    background: #FFF0F1;
    border-radius: 10px;
    padding: 30px 30px;
    text-align: center;
}

.vision_box .v_icon {
    background: #DD3944;
    height: 65px;
    width: 65px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 20px;
    color: #fff;
    font-size: 22px;
    transition: 0.5s;
}

.vision_box:hover .v_icon {
    transform: rotate(360deg);
}

.secure h3 {
    color: #FFC200;
    font-size: 60px;
    margin-top: 30px;
    font-weight: 600;
}

.secure_box {
    position: relative;
    padding-left: 35px;
    margin-top: 30px;
}

.secure_box img {
    position: absolute;
    left: 0;
    top: 4px;
}

.easy_deploy h3 {
    font-weight: 800;
    font-size: 30px;
}

.feature_box {
    text-align: center;
    background: #fff;
    border-radius: 10px;
    padding: 20px 8px;
}

.feature_box h6 {
    margin-bottom: 0;
    margin-top: 12px;
}

.signup_btns a {
    border: 1px solid #DD3944;
    text-decoration: none;
    color: #DD3944;
    display: inline-block;
    padding: 10px 22px;
    border-radius: 5px;
    margin: 0 5px;
    transition: 0.5s;
    font-weight: 500;
}

.signup_btns a:hover {
    background: #DD3944;
    color: #fff;
}

/*** Media Query ****/
@media only screen and (max-width:980px) {
    .megamenu .dropdown-menu li {
        width: 50%;
    }

    .megamenu .dropdown-menu.show {
        border-radius: 12px;
        padding: 10px;
        display: inline-block;
    }
}

@media only screen and (max-width:767px) {

    .arrow-top-down,
    .arrow-bottom-up,
    .manage_org h2 span:before {
        display: none;
    }

    .faq_sec.pt-5 {
        padding-top: 0 !important;
    }

    .faq_sec .question_mark {
        top: 35px;
        width: 80px;
        right: 35px;
    }

    .footer_menu li {
        margin-bottom: 0px;
    }

    .contact_details a {
        margin-top: 6px;
    }

    .footer_menu {
        margin-top: 0;
    }

    .feature_text {
        margin-top: 20px;
    }

    .main_banner h1 {
        margin-top: 0;
        font-size: 32px;
    }

    .banner-form {
        width: 100%;
        margin-bottom: 20px;
    }

    .big_heading {
        font-size: 28px;
    }

    .tabs_sec .nav-pills .nav-item {
        margin-right: 8px;
        margin-bottom: 8px;
    }

    .tabs_sec .nav-pills .nav-link {
        font-size: 14px;
        padding: 8px 10px;
    }

    .tabs_sec .big_heading span:before {
        left: -33px;
        top: -9px;
        width: 40px;
        height: 40px;
    }
}



.all_details_row {
    background: rgba(252, 194, 27, 0.2);
}

.page_label span {
    background: #ED6C30;
    display: inline-block;
    padding: 3px 12px;
    border-radius: 2px;
    color: #fff;
    margin-bottom: 15px;
}

.upgrade_circle {

    background-image: url(./assets/images/upgrade_bg.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 40px 0;
}

.upgrade_box .icon_up {
    background: #000;
    width: 60px;
    height: 60px;
    color: #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    margin-bottom: 10px;
    transition: 0.3s;
}

.upgrade_box:hover .icon_up {
    background: #dd3944;
}

.just_features h2 span:before {
    content: "";
    position: absolute;
    width: 32px;
    height: 30px;
    background: url(./assets/images/whats-new-brd.svg);
    background-size: contain;
    background-repeat: no-repeat;
    right: -33px;
    top: -12px;
}

.just_features {
    background-position: right center;
    background-size: cover;
}

.just_features .accordion-item {
    background-color: transparent;
    border: none;
}

.just_features .accordion-button {
    background-color: transparent !important;
    color: #fff !important;
    padding: 25px 15px 25px 35px;
}

.just_features .accordion-button:focus {
    box-shadow: none;
}

.just_features .accordion-header {
    margin-bottom: 0;
    border-bottom: 1px solid #fff;
}

.just_features .accordion-button::after {
    position: absolute;
    left: 0;
}

.just_features .accordion-body {
    background: #fff;
}

.just_features .accordion-button::after {
    position: absolute;
    left: 0;

    background-image: url(./assets/icons/plus.svg);
    width: 16px;
    height: 16px;
    background-size: contain;
}

.just_features .accordion-button:not(.collapsed)::after {

    background-image: url(./assets/icons/minus.svg);
    transform: none;
    top: 34px;
}
@media only screen and (max-width:480px) {
    .bottom_bar {
        padding: 12px 0;
        display: block;
        text-align: center;
    }
}